import React from "react"

export default ({tags}) => {
  const tagCount = 6
  let headTags = tags.slice(0, tagCount)
  return (
    <div className="tag-list">
      <div className="tag-title">#タグ</div>
      {headTags.map((value, index) => {
        return <a href={`/news/?tag=${encodeURIComponent(value)}`} key={index}><span>#{value}</span></a>
      })}
      <div className="link_arrow"><a href="/news/tags/">タグ一覧へ</a></div>
    </div>
  )
}