import React from "react"
import {Link} from "gatsby"
import _ from "lodash"
import DateText from "../DateText"
import style from "./newscard.module.css"
import NewsCategoryLabel from "../NewsCategoryLabel"

export default ({item}) => {
  // 画像パス
  let imageBaseUrl = ''
  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    imageBaseUrl = 'https://imgcdn.re-tapirs.com'
  } else {
    imageBaseUrl = 'https://imgcdn-stg.re-tapirs.com'
  }

  return (
    <Link className={style.card} to={`/news/detail/?id=${item.news_id}`}>
      <div>
        <p>
          <span className="date"><DateText text={item.stt_ts}/></span>
          <NewsCategoryLabel dsptgt_tc={item.dsptgt_tc} />
        </p>
        <h2>{item.title}</h2>
      </div>
      {_.size(item.thumbnail_url) > 0 && <div><img src={`${imageBaseUrl}/${item.thumbnail_url}`} alt={item.title} /></div>}
    </Link>
  )  
}
