import React from "react"
import _ from "lodash"
import classNames from "classnames"
import NewsCard from "../NewsCard";

export default ({items, loading}) => {
  //TODO: paging


  return (
    <div className={classNames({loading: loading}, 'news-wrapper')}>
      {!_.isEmpty(items) ? (
        _.map(items, item => <NewsCard item={item} key={item.news_id}/>)
      ) : (
        loading === false && <p style={{
          padding: "2em 0",
          textAlign: "center",
        }}>ニュースはございません。</p>
      )}
    </div>
  )
}